<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
      <div class="loader loader-7">
        <div class="line line1"></div>
        <div class="line line2"></div>
        <div class="line line3"></div>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true
    };
  },
  mounted() {
    this.timeOut();
  },
  methods: {
    timeOut() {
      // this.basketAddSuccess = true;
      var self = this;
      setTimeout(function() {
        self.show = false;
      }, 1000);
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>
<style>
.link_txt{
    color: blue;
    cursor: pointer;
}
tr{
  box-shadow: 0px 0px 1px 3px rgba(239, 238, 238, 0);
}
@media (max-width: 767.98px){
  tr{
  box-shadow: 0px 0px 1px 3px rgb(93, 92, 92);
}
}
</style>

<style>
th{
    text-align: unset !important;
}
.google_btn{
  cursor: pointer;
  border:1px solid rgb(88, 88, 245);
  border-radius: 5px;
}
.google_btn:hover{
  border:2px solid rgb(0, 0, 255);
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  font-size: 18px !important; 
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
}
.cust_url {
    display: block;
    max-width: 16em;
    order: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #487afa;
    cursor: pointer;
}
.cust_url:hover {
    color: #163b97;
    text-decoration: underline;
    max-width: auto;
    text-overflow: clip;
    white-space: normal;
}
.custom-pagination {
  display: inline-block;
}

.custom-pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.custom-pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.custom-pagination a:hover:not(.active) {background-color: #ddd; cursor: pointer;}

  /* Hide the arrow for month change in the date range picker */
.daterangepicker .drp-calendar .prev,
.daterangepicker .drp-calendar .next {
  background-color: #ffffff !important;
}
.daterangepicker.ltr .ranges li {
    color: #000000 !important;
    font-size: 12px !important;
}

.daterangepicker.ltr .ranges li:hover {
    color: #ffffff !important;
}
.daterangepicker.ltr tr td.active {
  background-color: #007bff !important;
}
.daterangepicker td.in-range {
  background-color: #edf9fd !important;
}
</style>
