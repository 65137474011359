import Vue from 'vue'
import Router from "vue-router";

import Userauth from '../auth/js/index'

import Body from '../components/body'
import Auth from '../auth/auth'

/* Auth */
const login = () => import('../auth/login')
const Verification = () => import('../auth/verification')
const ForgetPassword = () => import('../auth/forgetpassword')
const Register = () => import('../auth/register')

const Users = () => import('../views/manager/Users')
const ServiceProvider = () => import('../views/manager/serviceprovider/ServiceProvider')
const ServiceInfo = () => import('../views/manager/serviceinfo/ServiceInfoList')
const Transaction = () => import('../views/manager/Transcations/TranscationList')
const ServiceMapping = () => import('../views/manager/serviceMapping')
const SmartUrl = () => import('../views/manager/smartUrls/SmartUrls')
const Carrier = () => import('../views/manager/Carrier')

const Dashboard = () => import('../views/Dashboard')
const Dailies = () => import('../views/Dailies')
const Services = () => import('../views/Services')
const Notify = () => import('../views/Notify')
const ServiceInfoPublic = () => import('../views/publicInfo/ServiceInfo')

// component

Vue.use(Router)

const routes = [
{ path: '/', redirect: { name: 'Dashboard' }},
{
  path: '/auth',
  component: Auth,
  children: [
  {
    path: 'login',
    name: 'Login',
    component: login,
    meta: {
      title: ' login',
    }
  },
  {
    path: 'register',
    name: 'register',
    component: Register,
    meta: {
      title: ' Register',
    }
  },
  {
    path: 'forgetpassword',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: 'ForgetPassword',
    }
  },
  {
    path: 'verification',
    name: 'Verification',
    component: Verification,
    meta: {
      title: ' Verification',
    }
  },
  ]
},
{
  path: '/',
  component: Body,
  children: [
  {
    path: 'home',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    }
  },
  {
    path: 'daily',
    name: 'daily',
    component: Dailies,
    meta: {
      title: 'Dailies',
    }
  },
  {
    path: 'services',
    name: 'services',
    component: Services,
    meta: {
      title: 'Services',
    }
  },
]
},
{
  path: '/manager',
  component: Body,
  children: [
  {
    path: 'users',
    name: 'Users',
    component: Users,
    meta: {
      title: ' Users',
    }
  },
  {
    path: 'serviceprovider',
    name: 'serviceprovider',
    component: ServiceProvider,
    meta: {
      title: 'Service Provider',
    }
  },
  {
    path: 'serviceinfo',
    name: 'serviceinfo',
    component: ServiceInfo,
    meta: {
      title: 'Service Info',
    }
  },
  {
    path: 'transaction',
    name: 'transaction',
    component: Transaction,
    meta: {
      title: 'Transaction',
    }
  },
  {
    path: 'servicemapping',
    name: 'service mapping',
    component: ServiceMapping,
    meta: {
      title: 'ServiceMapping',
    }
  },
  {
    path: 'smarturl',
    name: 'smarturl',
    component: SmartUrl,
    meta: {
      title: 'SmartUrl',
    }
  },
  {
    path: 'carrier',
    name: 'carrier',
    component: Carrier,
    meta: {
      title: 'Carrier',
    }
  }
]},
{
  path:'/notify',
  name:'Notify',
  component:Notify,
  meta: {
      title: 'Notify',
    }
},
{
  path:'/servicedetail/:id',
  name:'Service Details',
  component:ServiceInfoPublic,
  meta: {
      title: 'Service Details',
    }
},
];

const router = new Router({
  routes,
  base: '/',
  mode: 'history', // hash or history
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  if(to.meta.title)
      document.title = to.meta.title;
      const  path = ['/auth/login','/auth/register','/auth/forgetpassword','/auth/verification'];
   if (path.includes(to.path) || (Userauth.isAuthenticated() && Userauth.checAlive()) || to.path.startsWith('/servicedetail')){
    return next();
   }
   next('/auth/login')

  // firebase.auth().onAuthStateChanged(() => {

  //   if(to.meta.title)
  //     document.title = to.meta.title;
  //   const CurrentUser = firebase.auth().currentUser;
  //   const  path = ['/auth/login','/auth/register'];
  //   if (path.includes(to.path) || CurrentUser && Userauth.isAuthenticated()){
  //     console.log('authenticated')
  //     return next();
  //   }
  //   next('/auth/login')
  // });
});


export default router
